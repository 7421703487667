import axios from "axios";
import { PdfDto } from "../documents/types";

type DownloadReportProps = {
  results?: PdfDto;
};

const DownloadReport = ({ results }: DownloadReportProps) => {
  if (!results) {
    return <></>;
  }

  const onSubmit = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/documents/pdf`,
        {
          positive: results.positive,
          negative: results.negative,
          neutral: results.neutral,
          mixed: results.mixed,
          anger: results.anger,
          fear: results.fear,
          disgust: results.disgust,
          joy: results.joy,
          empty: results.empty,
          sadness: results.sadness,
          surprise: results.surprise,
          positiveBaseline: results.positiveBaseline,
          negativeBaseline: results.negativeBaseline,
          neutralBaseline: results.neutralBaseline,
          mixedBaseline: results.mixedBaseline,
          emptyBaseline: results.emptyBaseline,
          angerBaseline: results.angerBaseline,
          fearBaseline: results.fearBaseline,
          disgustBaseline: results.disgustBaseline,
          joyBaseline: results.joyBaseline,
          sadnessBaseline: results.sadnessBaseline,
          surpriseBaseline: results.surpriseBaseline,
          baseline: results.baselineName,
          doc: results.docName,
          analyzedAt: results.analyzedAt,
          baselineAnalyzedAt: results.baselineAnalyzedAt,
          keyPhrases: results.keyPhrases,
          topNegativeKeyPhrases: results.topNegativeKeyPhrases,
          topPositiveKeyPhrases: results.topPositiveKeyPhrases,
          intermediateDocs: JSON.stringify(results.intermediateDocs),
        },
        { responseType: "blob" }
      );

      const data = new Blob([res.data]);
      const downloadUrl = URL.createObjectURL(data);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = results.baselineName
        ? `${results.docName} - ${results.baselineName} comparison.pdf`
        : `${results.docName}.pdf`;
      link.click();
      URL.revokeObjectURL(downloadUrl);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <button
        type="button"
        onClick={onSubmit}
        className="bg-gradient-to-r from-green-400 to-blue-500 hover:from-pink-500 hover:to-yellow-500 font-medium rounded-lg text-sm w-48 px-5 py-2.5 text-center text-white float-right"
      >
        Download report
      </button>
    </div>
  );
};

export default DownloadReport;
