import { KeyPhraseDto } from "../../documents/types";
import { TagCloud } from "react-tagcloud";

type KeyPhrasesProps = {
  results: KeyPhraseDto[];
  className?: string;
};

const KeyPhrasesCloud = ({ results, className }: KeyPhrasesProps) => {
  return (
    <div className="p-8 mb-8">
      <div className="text-xl font-normal text-center mb-6">KeyWords Cloud</div>
      <TagCloud minSize={12} maxSize={35} tags={results} />
    </div>
  );
};

export default KeyPhrasesCloud;
