import { Toaster } from "react-hot-toast";
import "./App.css";
import Page from "./Page";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import Results from "./results/Results";
import Pdf from "./results/pdf/Pdf";

let router = createBrowserRouter([
  {
    path: "/",
    Component() {
      return (
        <div className="App">
          <Page />
          <Toaster />
        </div>
      );
    },
  },
  {
    path: "/report",
    Component() {
      return <Pdf />;
    },
  },
  {
    path: "/results",
    Component() {
      return <Results />;
    },
  },
  {
    path: "*",
    Component() {
      return <Navigate to={"/"} />;
    },
  },
]);

export default function App() {
  return <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />;
}
