import { KeyPhraseSentimentDto } from "../../documents/types";

type TopPositiveKeyPhrasesProps = {
  results: KeyPhraseSentimentDto;
  className?: string;
};

export const TopPositiveKeyPhrases = ({
  results,
  className,
}: TopPositiveKeyPhrasesProps) => {
  return (
    <div className="p-8 mb-8">
      <div className="text-xl font-normal text-center mb-6">
        Top Positive Keywords
      </div>
      <ul className="list-none list-inside">
        {results.map((el) => {
          return (
            <li className="my-4" key={el}>
              <span className="bg-green-400 py-1.5 px-2 rounded-lg">{el}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
