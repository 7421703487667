import { useState } from "react";
import { AuthContext } from "./AuthContext";
import UploadingDocuments from "./documents/UploadDocuments";
import Login from "./login/Login";

const Page = () => {
  const [isAuthUser, setAuthUser] = useState<boolean>(false);
  return (
    <>
      <AuthContext.Provider value={{ isAuthUser, setAuthUser }}>
        <div className="container mx-auto h-screen">
          <div className="flex flex-row flex-wrap py-4   h-full">
            <main
              role="main"
              className="w-full pt-1 px-2 border-cyan-100 border-2 mb-10 "
            >
              <Login />
              {isAuthUser && <UploadingDocuments />}
            </main>
          </div>
        </div>
      </AuthContext.Provider>
    </>
  );
};

export default Page;
