import DistributionTable from "./DistributionTable";
import DistributionChart from "./DistributionChart";
import { calculatePercentages } from "@/utils/utils";

export type DistributionElementType<T> = {
  value: number;
  type: keyof T;
};

type DistributionBlockProps<T> = {
  results: {
    elements: DistributionElementType<T>[];
    elementsBaseline: DistributionElementType<T>[];
    elementDiv: string;
    colors: { name: string; value: string }[];
  };
  title: string;
  className?: string;
  elementWrapperClassName?: string;
  gridColumnNumber?: number;
};

export const DistributionBlock = <T extends Record<string, number>>({
  results,
  title,
  className,
  elementWrapperClassName,
  gridColumnNumber,
}: DistributionBlockProps<T>) => {
  const percentData = calculatePercentages<T>(results.elements);
  const percentDataBaseline = calculatePercentages<T>(results.elementsBaseline);

  return (
    <div className={`${className}`}>
      <div className="text-xl font-normal text-center mb-6">{title}</div>
      <div className="flex flex-row">
        <DistributionTable<T>
          percentData={percentData}
          percentDataBaseline={percentDataBaseline}
          colors={results.colors}
          className="flex-initial w-30"
          elementWrapperClassName={elementWrapperClassName}
          gridColumnNumber={gridColumnNumber}
        />
        <DistributionChart<T>
          percentData={percentData}
          percentDataBaseline={percentDataBaseline}
          colors={results.colors}
          elementDiv={results.elementDiv}
          className="flex-initial w-full mr-4"
        />
      </div>
    </div>
  );
};
