export function calculatePercentages<T extends Record<string, number>>(
  data: { type: keyof T; value: number; title?: string }[]
): { type: keyof T; value: number; title: string }[] {
  const sum = getSum(data.map((el) => el.value));

  const arr = [] as { type: keyof T; value: number; title: string }[];
  data.forEach((el) => {
    arr.push({
      type: el.type,
      value: calculatePercentage(el.value, sum),
      title: el.title || String(el.type),
    });
  });

  return arr;
}

export function getSum(data: Array<number>): number {
  return data.reduce((prev, el) => {
    return Number(prev + Number(el));
  }, 0);
}

export function calculatePercentage(value: number, sum: number): number {
  return Number(Number((value * 100) / sum).toFixed(2)) || 0;
}

export function calculateDifference(aVal: number, bVal: number) {
  return Number(Math.abs(aVal - bVal).toFixed(2));
}

export const titleCase = (str: string) =>
  `${str[0].toUpperCase()}${str.slice(1).toLowerCase()}`;
