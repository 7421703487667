import { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import { calculatePercentages } from "@/utils/utils";
import { BASELINE, NEW_DOC } from "@/utils/colors";

type DistributionChartProps<T extends Record<string, number>> = {
  percentData: ReturnType<typeof calculatePercentages<T>>;
  percentDataBaseline: ReturnType<typeof calculatePercentages<T>>;
  colors: { name: string; value: string }[];
  elementDiv: string;
  className?: string;
};

const DistributionChart = <T extends Record<string, number>>({
  percentData,
  percentDataBaseline,
  elementDiv,
  className,
}: DistributionChartProps<T>) => {
  useLayoutEffect(() => {
    let root = am5.Root.new(elementDiv);
    root?._logo?.dispose();

    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "none",
        wheelY: "none",
        layout: root.verticalLayout,
        paddingTop: 30,
      })
    );
    var data = percentData.map((el) => {
      return {
        category: el.type,
        newDoc: el.value,
        baseline: percentDataBaseline.find(
          (baseLine) => baseLine.type === el.type
        )?.value,
      };
    });

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/

    var yRenderer = am5xy.AxisRendererY.new(root, {
      minorGridEnabled: false,
    });
    yRenderer.grid.template.set("location", 1);

    yRenderer.labels.template.set("forceHidden", true);

    var yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "category",
        renderer: yRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    yAxis.data.setAll(data);

    let xRenderer = am5xy.AxisRendererX.new(root, {
      strokeOpacity: 0.1,
      minGridDistance: 100,
    });
    xRenderer.grid.template.set("forceHidden", true);
    xRenderer.labels.template.set("forceHidden", true);

    var xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: xRenderer,
        maxDeviation: 0.3,
        min: 0,
        max: 100,
      })
    );

    function createRange(value: any, axis: any, label: any) {
      let rangeDataItem = axis.makeDataItem({
        value: value,
      });

      let range = axis.createAxisRange(rangeDataItem);

      range.get("label").setAll({
        forceHidden: false,
        text: label,
      });

      range.get("grid").setAll({
        forceHidden: false,
        strokeOpacity: 0.2,
        location: 1,
      });
    }

    createRange(0, xAxis, "0%");
    createRange(50, xAxis, "50%");
    createRange(100, xAxis, "100%");

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    // series here stands for newDoc
    var series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,

        valueXField: "newDoc",
        categoryYField: "category",
        fill: am5.color(0x888888),
      })
    );

    series.columns.template.setAll({
      height: 10,
      opacity: 0,
    });

    series.data.setAll(data);

    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        locationX: 1,
        sprite: am5.Graphics.new(root, {
          fill: am5.color(NEW_DOC),
          rotation: 45,
          draw: function (display) {
            display.drawRect(0, 0, 15, 15);
          },
        }),
      });
    });

    // series2 here stands for baseline
    var series2 = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "Baseline",
        xAxis: xAxis,
        yAxis: yAxis,
        valueXField: "baseline",
        categoryYField: "category",
      })
    );

    series2.strokes.template.setAll({
      visible: false,
    });

    series2.data.setAll(data);

    // Add bullets
    series2.bullets.push(function () {
      return am5.Bullet.new(root, {
        sprite: am5.Graphics.new(root, {
          fill: am5.color(BASELINE),
          rotation: 45,
          dy: -10,
          draw: function (display) {
            display.drawRect(0, 0, 15, 15);
          },
        }),
      });
    });

    var series3 = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "Comparison",
        xAxis: xAxis,
        yAxis: yAxis,
      })
    );

    series3.strokes.template.setAll({
      visible: false,
    });

    series3.data.setAll(data);

    // Add bullets
    series3.bullets.push(function () {
      return am5.Bullet.new(root, {
        locationX: 0,
        sprite: am5.Graphics.new(root, {
          fill: am5.color(NEW_DOC),
          rotation: 45,
          dy: -10,
          draw: function (display) {
            display.drawRect(0, 0, 15, 15);
          },
        }),
      });
    });

    // Add legend
    var legend = chart.children.push(
      am5.Legend.new(root, {
        layout: root.horizontalLayout,
        clickTarget: "none",
        dx: root.container.width() / 2,
        centerX: am5.p50,
      })
    );
    legend.valueLabels.template.setAll({
      fontSize: 14,
    });
    legend.labels.template.setAll({
      fontSize: 14,
    });

    legend.data.setAll([series3, series2]);

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series.appear();
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [elementDiv, percentData, percentDataBaseline]);

  return (
    <div className={`${className}`}>
      <div
        id={`${elementDiv}`}
        style={{ width: "100%", height: "500px" }}
      ></div>
    </div>
  );
};

export default DistributionChart;
