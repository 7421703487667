import { ErrorType } from "../types";

type LoginFormProps = {
  setUsername: (val: string) => void;
  setPassword: (val: string) => void;
  handleSubmit: () => void;
  isDisabled: boolean;
  errors?: ErrorType;
};

const LoginForm = ({
  setUsername,
  setPassword,
  handleSubmit,
  isDisabled,
  errors,
}: LoginFormProps) => {
  const OnSubmit = () => {
    handleSubmit();
  };

  const isUsernameErrored = errors && errors.field === "username";
  const isPasswordErrored = errors && errors.field === "password";

  return (
    <>
      <div className="flex flex-col">
        <div className="mb-6">
          <label
            htmlFor="username"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Username
          </label>
          <input
            type="string"
            id="username"
            className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
              isUsernameErrored && "border-red-500"
            }`}
            placeholder="john.doe@company.com"
            required
            onChange={(e) => {
              setUsername(e.target.value);
            }}
          />
          {isUsernameErrored && (
            <div className="text-red-500 text-xs mt-1">{errors.message}</div>
          )}
        </div>
        <div className="mb-6">
          <label
            htmlFor="password"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Password
          </label>
          <input
            type="password"
            id="password"
            className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  ${
              isPasswordErrored && "border-red-500"
            }`}
            placeholder="•••••••••"
            required
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          {isPasswordErrored && (
            <div className="text-red-500 text-xs mt-1">{errors.message}</div>
          )}
        </div>
        <button
          type="submit"
          className={`text-white ${
            !isDisabled ? "bg-blue-700 hover:bg-blue-800" : "bg-gray-500"
          } focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`}
          onClick={OnSubmit}
          disabled={isDisabled}
        >
          Submit
        </button>
      </div>
    </>
  );
};

export default LoginForm;
