import UploadForm from "./UploadForm";
import axios, { AxiosError } from "axios";
import toast from "react-hot-toast";
import { ErrorResponse } from "../types";
import {
  SocketResultPayloadElement,
  UploadDocumentType,
  NotificationMessage,
  StepsType,
} from "./types";
import { useEffect, useState } from "react";
import Steps from "./Spinner";
import io, { Socket } from "socket.io-client";
import ResultsButton from "./ResultsButton";

const UploadingDocuments = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [stepsData, setStepsData] = useState<StepsType>({});
  const [isDone, setIsDone] = useState(false);
  const [results, setResults] = useState<SocketResultPayloadElement>();
  const [shouldRefetch, setShouldRefetch] = useState<boolean>(false);
  const [isBaseline, setIsBaseline] = useState<boolean>(false);

  const [socket, setSocket] = useState<Socket>();

  useEffect(() => {
    const newS = io(`${process.env.REACT_APP_API_URL}`);
    setSocket(newS);

    return () => {
      newS.disconnect();
    };
  }, [setSocket]);

  const messageHandler = (newData: NotificationMessage) => {
    if (newData.type === "progress") {
      setStepsData((prevState: StepsType) => {
        const newState = newData.payload as StepsType;
        if (Object.keys(newState).includes("error")) {
          Object.values(prevState).map((el) => {
            return { ...el, isDone: true };
          });

          for (let key in prevState) {
            newState[key] = { ...prevState[key], isDone: true };
          }
        }

        return { ...prevState, ...newState };
      });
    }
    if (newData.type === "result") {
      setResults(newData.payload as SocketResultPayloadElement);
    }
  };

  useEffect(() => {
    if (isSubmitted) {
      setIsDone(false);
    }
  }, [isSubmitted]);

  useEffect(() => {
    if (
      Object.keys(stepsData).findIndex((el) => el === "analyzing") > 0 &&
      stepsData.analyzing.isDone &&
      results &&
      isBaseline
    ) {
      setShouldRefetch(true);
      setIsBaseline(false);
    }
  }, [isBaseline, results, stepsData]);

  useEffect(() => {
    if (
      Object.keys(stepsData).findIndex((el) => el === "analyzing") > 0 &&
      stepsData.analyzing.isDone &&
      results
    ) {
      setIsDone(true);
    }
  }, [results, stepsData]);

  useEffect(() => {
    socket?.on("connect", () => {
      console.log("is connected", socket.id);
      setStepsData({});
    });
    socket?.on("events", messageHandler);

    return () => {
      socket?.off("events", messageHandler);
    };
  }, [socket]);

  const handleSubmit = async (data: UploadDocumentType) => {
    try {
      setStepsData({});
      const formData = new FormData();
      formData.append("document", data.file, data.file.name);
      formData.append(
        "intermediateDocumentIds",
        data.intermediateSelected.join(",")
      );
      if (socket) {
        formData.append("socketId", socket.id);
      }
      if (data.isBaseline) {
        setIsBaseline(true);
        formData.append("isBaseline", "true");
      }
      if (data.analyzedAt) {
        formData.append("analyzedAt", data.analyzedAt.toISOString());
      }
      if (data.toCompareWithDocumentId) {
        formData.append("toCompareWithDocumentId", data.toCompareWithDocumentId);
      }

      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/documents`,
        formData,
        {
          headers: {
            socketId: socket?.id,
          },
        }
      );

      setIsSubmitted(true);
      setShouldRefetch(false);
    } catch (error) {
      setStepsData({});
      const err = error as AxiosError<ErrorResponse>;

      toast.error(err.response?.data.errors.message || "Something went wrong", {
        position: "top-right",
      });
    }
  };

  return (
    <>
      <UploadForm
        handleSubmit={handleSubmit}
        onResetForm={setIsSubmitted}
        isSent={isSubmitted}
        shouldRefetch={shouldRefetch}
      />
      <Steps steps={stepsData} />
      {isDone && <ResultsButton results={results} />}
    </>
  );
};

export default UploadingDocuments;
