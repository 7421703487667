import { Navigate, useLocation } from "react-router-dom";
import PieChart from "./components/PieChart";
import StackedColumnChart from "./components/StackedColumnChart";
import ClusteredColumnChart from "./components/ClusteredColumnChart";
import { useEffect, useMemo, useState } from "react";
import axios, { AxiosError } from "axios";
import { ErrorResponse } from "../types";
import toast from "react-hot-toast";
import {
  EmotiontDto,
  SentimentDto,
  SocketResultPayloadElement,
} from "../documents/types";
import {
  ANGER,
  DISGUST,
  EMPTY,
  FEAR,
  JOY,
  MIXED,
  NEGATIVE,
  NEUTRAL,
  POSITIVE,
  SADNESS,
  SURPRISE,
} from "./utils/colors";
import DownloadReport from "./DownloadReport";
import KeyPhrasesCloud from "@/components/KeyPhrasesCloud";
import { TopPositiveKeyPhrases } from "@/components/TopPositiveKeyPhrases";
import { TopNegativeKeyPhrases } from "@/components/TopNegativeKeyPhrases";
import { DistributionBlock } from "@/components/DistributionBlock";
import EvolutionChart from "@/components/EvolutionChart";
import { calculatePercentage, getSum } from "./utils/utils";

type DocumentElemType = {
  id: string;
  name: string;
  positive: number;
  negative: number;
  neutral: number;
  mixed: number;
  joy: number;
  anger: number;
  sadness: number;
  disgust: number;
  surprise: number;
  fear: number;
  empty: number;
  analyzedAt: Date;
};

const Results = () => {
  let location = useLocation();
  const state = location.state as SocketResultPayloadElement;

  const [baseline, setBaseline] = useState<DocumentElemType | null>(null);

  const currentDate = new Date(state.analyzedAt) || new Date();

  const emotionSum = useMemo(() => {
    if (!state.emotions) return 0;

    return getSum([
      state.emotions.anger,
      state.emotions.fear,
      state.emotions.disgust,
      state.emotions.joy,
      state.emotions.sadness,
      state.emotions.surprise,
      state.emotions.empty,
    ]);
  }, [state.emotions]);

  const emotionBaselineSum = useMemo(() => {
    if (!baseline) return 0;
    return getSum([
      baseline.anger,
      baseline.fear,
      baseline.disgust,
      baseline.joy,
      baseline.sadness,
      baseline.surprise,
      baseline.empty,
    ]);
  }, [baseline]);

  const sentimentSum = useMemo(() => {
    return getSum([state.positive, state.negative, state.neutral, state.mixed]);
  }, [state.mixed, state.negative, state.neutral, state.positive]);

  const sentimentBaselineSum = useMemo(() => {
    return getSum([
      baseline?.positive || 0,
      baseline?.negative || 0,
      baseline?.neutral || 0,
      baseline?.mixed || 0,
    ]);
  }, [
    baseline?.mixed,
    baseline?.negative,
    baseline?.neutral,
    baseline?.positive,
  ]);

  useEffect(() => {
    if (!!state && state.baseline) {
      const fetch = async () => {
        try {
          const result = await axios.get<
            DocumentElemType & { noEmotions: number | null }
          >(`${process.env.REACT_APP_API_URL}/api/documents/${state.baseline}`);

          setBaseline({
            ...result.data,
            empty: Number(result.data.noEmotions),
            analyzedAt: new Date(result.data.analyzedAt),
          });
        } catch (error) {
          const err = error as AxiosError<ErrorResponse>;
          console.log(err.response?.data.errors);

          toast.error("Something went wrong", {
            position: "top-right",
          });
        }
      };
      fetch();
    }
  }, [state]);

  const fileName = useMemo(() => {
    return baseline?.name
      ? `${state.doc} Analysis Report and Comparison with ${baseline.name}`
      : `${state.doc} Analysis Report`;
  }, [baseline?.name, state.doc]);

  if (!state) {
    return (
      <>
        <Navigate to={"/"} />
      </>
    );
  }

  return (
    <div>
      <div className="flex flex-col overflow-x-auto space-x-8 w-1/2 mx-auto min-w-[880px] ">
        <div className="text-4xl font-normal p-8 text-center mb-10">
          {fileName}
        </div>
        <DownloadReport
          results={{
            anger: state.emotions.anger,
            disgust: state.emotions.disgust,
            fear: state.emotions.fear,
            joy: state.emotions.joy,
            mixed: state.mixed,
            negative: state.negative,
            neutral: state.neutral,
            positive: state.positive,
            sadness: state.emotions.sadness,
            surprise: state.emotions.surprise,
            empty: state.emotions.empty,
            analyzedAt: currentDate.getTime(),
            docName: state.doc,
            keyPhrases: JSON.stringify(state.keyPhrases),
            topNegativeKeyPhrases: JSON.stringify(state.keyPhrasesNegative),
            topPositiveKeyPhrases: JSON.stringify(state.keyPhrasesPositive),
            intermediateDocs: state.intermediateDocs,
            ...(baseline && {
              baselineAnalyzedAt: baseline.analyzedAt.getTime(),
              emptyBaseline: baseline.empty,
              angerBaseline: baseline.anger,
              disgustBaseline: baseline.disgust,
              fearBaseline: baseline.fear,
              joyBaseline: baseline.joy,
              sadnessBaseline: baseline.sadness,
              surpriseBaseline: baseline.surprise,
              positiveBaseline: baseline.positive,
              mixedBaseline: baseline.mixed,
              negativeBaseline: baseline.negative,
              neutralBaseline: baseline.neutral,
              baselineName: baseline.name,
            }),
          }}
        />
        <EvolutionChart<
          Pick<
            EmotiontDto,
            | "anger"
            | "disgust"
            | "fear"
            | "joy"
            | "sadness"
            | "surprise"
            | "empty"
          >
        >
          results={{
            elementDiv: "emotion_evolution_chart",
            elements: [
              {
                type: "anger",
                color: ANGER,
                data: [
                  ...(baseline
                    ? [
                        {
                          date: baseline.analyzedAt.getTime(),
                          value: calculatePercentage(
                            baseline.anger,
                            emotionBaselineSum
                          ),
                        },
                      ]
                    : []),
                  ...state.intermediateDocs.map((el) => {
                    const sum = getSum([
                      el.anger,
                      el.fear,
                      el.disgust,
                      el.joy,
                      el.sadness,
                      el.surprise,
                    ]);
                    return {
                      date: new Date(el.analyzedAt).getTime(),
                      value: calculatePercentage(el.anger, sum),
                    };
                  }),
                  {
                    date: currentDate.getTime(),
                    value: calculatePercentage(
                      state.emotions.anger,
                      emotionSum
                    ),
                  },
                ],
              },
              {
                type: "empty",
                color: EMPTY,
                title: "No Emotions",
                data: [
                  ...(baseline
                    ? [
                        {
                          date: baseline.analyzedAt.getTime(),
                          value: calculatePercentage(
                            baseline.empty,
                            emotionBaselineSum
                          ),
                        },
                      ]
                    : []),
                  ...state.intermediateDocs.map((el) => {
                    const sum = getSum([
                      el.anger,
                      el.fear,
                      el.disgust,
                      el.joy,
                      el.sadness,
                      el.surprise,
                      el.empty,
                    ]);
                    return {
                      date: new Date(el.analyzedAt).getTime(),
                      value: calculatePercentage(el.empty, sum),
                    };
                  }),
                  {
                    date: currentDate.getTime(),
                    value: calculatePercentage(
                      state.emotions.empty,
                      emotionSum
                    ),
                  },
                ],
              },
              {
                type: "disgust",
                color: DISGUST,
                data: [
                  ...(baseline
                    ? [
                        {
                          date: baseline.analyzedAt.getTime(),
                          value: calculatePercentage(
                            baseline.disgust,
                            emotionBaselineSum
                          ),
                        },
                      ]
                    : []),
                  ...state.intermediateDocs.map((el) => {
                    const sum = getSum([
                      el.anger,
                      el.fear,
                      el.disgust,
                      el.joy,
                      el.sadness,
                      el.surprise,
                    ]);
                    return {
                      date: new Date(el.analyzedAt).getTime(),
                      value: calculatePercentage(el.disgust, sum),
                    };
                  }),
                  {
                    date: currentDate.getTime(),
                    value: calculatePercentage(
                      state.emotions.disgust,
                      emotionSum
                    ),
                  },
                ],
              },
              {
                type: "fear",
                color: FEAR,
                data: [
                  ...(baseline
                    ? [
                        {
                          date: baseline.analyzedAt.getTime(),
                          value: calculatePercentage(
                            baseline.fear,
                            emotionBaselineSum
                          ),
                        },
                      ]
                    : []),
                  ...state.intermediateDocs.map((el) => {
                    const sum = getSum([
                      el.anger,
                      el.fear,
                      el.disgust,
                      el.joy,
                      el.sadness,
                      el.surprise,
                    ]);
                    return {
                      date: new Date(el.analyzedAt).getTime(),
                      value: calculatePercentage(el.fear, sum),
                    };
                  }),
                  {
                    date: currentDate.getTime(),
                    value: calculatePercentage(state.emotions.fear, emotionSum),
                  },
                ],
              },
              {
                type: "joy",
                title: "Contentment",
                color: JOY,
                data: [
                  ...(baseline
                    ? [
                        {
                          date: baseline.analyzedAt.getTime(),
                          value: calculatePercentage(
                            baseline.joy,
                            emotionBaselineSum
                          ),
                        },
                      ]
                    : []),
                  ...state.intermediateDocs.map((el) => {
                    const sum = getSum([
                      el.anger,
                      el.fear,
                      el.disgust,
                      el.joy,
                      el.sadness,
                      el.surprise,
                    ]);
                    return {
                      date: new Date(el.analyzedAt).getTime(),
                      value: calculatePercentage(el.joy, sum),
                    };
                  }),
                  {
                    date: currentDate.getTime(),
                    value: calculatePercentage(state.emotions.joy, emotionSum),
                  },
                ],
              },
              {
                type: "sadness",
                color: SADNESS,
                data: [
                  ...(baseline
                    ? [
                        {
                          date: baseline.analyzedAt.getTime(),
                          value: calculatePercentage(
                            baseline.sadness,
                            emotionBaselineSum
                          ),
                        },
                      ]
                    : []),
                  ...state.intermediateDocs.map((el) => {
                    const sum = getSum([
                      el.anger,
                      el.fear,
                      el.disgust,
                      el.joy,
                      el.sadness,
                      el.surprise,
                    ]);
                    return {
                      date: new Date(el.analyzedAt).getTime(),
                      value: calculatePercentage(el.sadness, sum),
                    };
                  }),
                  {
                    date: currentDate.getTime(),
                    value: calculatePercentage(
                      state.emotions.sadness,
                      emotionSum
                    ),
                  },
                ],
              },
              {
                type: "surprise",
                color: SURPRISE,
                data: [
                  ...(baseline
                    ? [
                        {
                          date: baseline.analyzedAt.getTime(),
                          value: calculatePercentage(
                            baseline.surprise,
                            emotionBaselineSum
                          ),
                        },
                      ]
                    : []),
                  ...state.intermediateDocs.map((el) => {
                    const sum = getSum([
                      el.anger,
                      el.fear,
                      el.disgust,
                      el.joy,
                      el.sadness,
                      el.surprise,
                    ]);
                    return {
                      date: new Date(el.analyzedAt).getTime(),
                      value: calculatePercentage(el.surprise, sum),
                    };
                  }),
                  {
                    date: currentDate.getTime(),
                    value: calculatePercentage(
                      state.emotions.surprise,
                      emotionSum
                    ),
                  },
                ],
              },
            ],
          }}
          title="Emotions Evolution Over Time"
        />
        <KeyPhrasesCloud results={state.keyPhrases} />
        <div className="flex flex-row flex-wrap">
          <div className="flex-auto w-1/2">
            <TopPositiveKeyPhrases results={state.keyPhrasesPositive} />
          </div>
          <div className="flex-auto w-1/2">
            <TopNegativeKeyPhrases results={state.keyPhrasesNegative} />
          </div>
        </div>

        <PieChart
          results={{
            colors: [EMPTY, ANGER, DISGUST, FEAR, JOY, SADNESS, SURPRISE],
            elementDiv: "emotion_chartdiv",
            elements: [
              {
                value: state.emotions.empty,
                category: "No emotions",
              },
              {
                value: state.emotions.anger,
                category: "Anger",
              },
              {
                value: state.emotions.disgust,
                category: "Disgust",
              },
              {
                value: state.emotions.fear,
                category: "Fear",
              },
              {
                value: state.emotions.joy,
                category: "Contentment",
              },
              {
                value: state.emotions.sadness,
                category: "Sadness",
              },
              {
                value: state.emotions.surprise,
                category: "Surprise",
              },
            ],
          }}
          title="“Pie” chart to show emotion distribution of new document"
          className="mt-8"
        />
        {!!baseline && (
          <DistributionBlock
            gridColumnNumber={6}
            results={{
              colors: [
                {
                  name: "Anger",
                  value: ANGER,
                },
                {
                  name: "Disgust",
                  value: DISGUST,
                },
                {
                  name: "Fear",
                  value: FEAR,
                },
                {
                  name: "Contentment",
                  value: JOY,
                },
                {
                  name: "Sadness",
                  value: SADNESS,
                },
                {
                  name: "Surprise",
                  value: SURPRISE,
                },
                {
                  name: "No Emotions",
                  value: EMPTY,
                },
              ],
              elementDiv: "emotion_chart_distribution",
              elements: [
                {
                  value: state.emotions.anger,
                  type: "Anger",
                },
                {
                  value: state.emotions.disgust,
                  type: "Disgust",
                },
                {
                  value: state.emotions.fear,
                  type: "Fear",
                },
                {
                  value: state.emotions.joy,
                  type: "Joy",
                },
                {
                  value: state.emotions.sadness,
                  type: "Sadness",
                },
                {
                  value: state.emotions.surprise,
                  type: "Surprise",
                },
                {
                  value: state.emotions.empty,
                  type: "Empty",
                },
              ],
              elementsBaseline: [
                {
                  value: baseline.anger,
                  type: "Anger",
                },
                {
                  value: baseline.disgust,
                  type: "Disgust",
                },
                {
                  value: baseline.fear,
                  type: "Fear",
                },
                {
                  value: baseline.joy,
                  type: "Joy",
                },
                {
                  value: baseline.sadness,
                  type: "Sadness",
                },
                {
                  value: baseline.surprise,
                  type: "Surprise",
                },
                {
                  value: baseline.empty,
                  type: "Empty",
                },
              ],
            }}
            title="Emotions Distribution & Comparison Against Baseline"
            className="mt-12"
            elementWrapperClassName="top-[8px]"
          />
        )}
        {!!baseline && (
          <StackedColumnChart<
            Pick<
              EmotiontDto,
              | "anger"
              | "disgust"
              | "fear"
              | "joy"
              | "sadness"
              | "surprise"
              | "empty"
            >
          >
            results={{
              elementDiv: "emotion_stacked_chart",
              elements: [
                {
                  baselineValue: baseline.anger,
                  color: ANGER,
                  position: "left",
                  type: "anger",
                  value: state.emotions.anger,
                },
                {
                  baselineValue: baseline.disgust,
                  color: DISGUST,
                  position: "right",
                  type: "disgust",
                  value: state.emotions.disgust,
                },
                {
                  baselineValue: baseline.fear,
                  color: FEAR,
                  position: "left",
                  type: "fear",
                  value: state.emotions.fear,
                },
                {
                  baselineValue: baseline.joy,
                  color: JOY,
                  position: "right",
                  type: "joy",
                  value: state.emotions.joy,
                  title: "Contentment",
                },
                {
                  baselineValue: baseline.sadness,
                  color: SADNESS,
                  position: "left",
                  type: "sadness",
                  value: state.emotions.sadness,
                },
                {
                  baselineValue: baseline.surprise,
                  color: SURPRISE,
                  position: "right",
                  type: "surprise",
                  value: state.emotions.surprise,
                },
                {
                  baselineValue: baseline.empty,
                  color: EMPTY,
                  position: "left",
                  type: "empty",
                  value: state.emotions.empty,
                  title: "No Emotions",
                },
              ],
            }}
            title="Stacked Bar Chart: Breakdown Comparison of Emotions"
            className="mt-12"
          />
        )}
        {!!baseline && (
          <ClusteredColumnChart<
            Pick<
              EmotiontDto,
              | "anger"
              | "disgust"
              | "fear"
              | "joy"
              | "sadness"
              | "surprise"
              | "empty"
            >
          >
            results={{
              elementDiv: "emotion_clustered_chart",
              elements: [
                {
                  value: state.emotions.anger,
                  type: "anger",
                },
                {
                  value: state.emotions.disgust,
                  type: "disgust",
                },
                {
                  value: state.emotions.fear,
                  type: "fear",
                },
                {
                  value: state.emotions.joy,
                  type: "joy",
                  title: "Contentment",
                },
                {
                  value: state.emotions.sadness,
                  type: "sadness",
                },
                {
                  value: state.emotions.surprise,
                  type: "surprise",
                },
                {
                  value: state.emotions.empty,
                  type: "empty",
                  title: "No Emotions",
                },
              ],
              elementsBaseline: [
                {
                  value: baseline.anger,
                  type: "anger",
                },
                {
                  value: baseline.disgust,
                  type: "disgust",
                },
                {
                  value: baseline.fear,
                  type: "fear",
                },
                {
                  value: baseline.joy,
                  type: "joy",
                  title: "Contentment",
                },
                {
                  value: baseline.sadness,
                  type: "sadness",
                },
                {
                  value: baseline.surprise,
                  type: "surprise",
                },
                {
                  value: baseline.empty,
                  type: "empty",
                  title: "No Emotions",
                },
              ],
            }}
            title="Bar Charts & Percent Difference: Individual Emotion Scores"
            className="mt-12"
            elementClassName="h-[500px]"
          />
        )}
        <EvolutionChart<
          Pick<SentimentDto, "mixed" | "negative" | "neutral" | "positive">
        >
          results={{
            elementDiv: "evolution_chart",
            elements: [
              {
                type: "mixed",
                color: MIXED,
                data: [
                  ...(baseline
                    ? [
                        {
                          date: baseline.analyzedAt.getTime(),
                          value: calculatePercentage(
                            baseline.mixed,
                            sentimentBaselineSum
                          ),
                        },
                      ]
                    : []),
                  ...state.intermediateDocs.map((el) => {
                    const sum = getSum([
                      el.mixed,
                      el.positive,
                      el.negative,
                      el.neutral,
                    ]);
                    return {
                      date: new Date(el.analyzedAt).getTime(),
                      value: calculatePercentage(el.mixed, sum),
                    };
                  }),
                  {
                    date: currentDate.getTime(),
                    value: calculatePercentage(state.mixed, sentimentSum),
                  },
                ],
              },
              {
                type: "negative",
                color: NEGATIVE,
                data: [
                  ...(baseline
                    ? [
                        {
                          date: baseline.analyzedAt.getTime(),
                          value: calculatePercentage(
                            baseline.negative,
                            sentimentBaselineSum
                          ),
                        },
                      ]
                    : []),
                  ...state.intermediateDocs.map((el) => {
                    const sum = getSum([
                      el.mixed,
                      el.positive,
                      el.negative,
                      el.neutral,
                    ]);
                    return {
                      date: new Date(el.analyzedAt).getTime(),
                      value: calculatePercentage(el.negative, sum),
                    };
                  }),
                  {
                    date: currentDate.getTime(),
                    value: calculatePercentage(state.negative, sentimentSum),
                  },
                ],
              },
              {
                type: "neutral",
                color: NEUTRAL,
                data: [
                  ...(baseline
                    ? [
                        {
                          date: baseline.analyzedAt.getTime(),
                          value: calculatePercentage(
                            baseline.neutral,
                            sentimentBaselineSum
                          ),
                        },
                      ]
                    : []),
                  ...state.intermediateDocs.map((el) => {
                    const sum = getSum([
                      el.mixed,
                      el.positive,
                      el.negative,
                      el.neutral,
                    ]);
                    return {
                      date: new Date(el.analyzedAt).getTime(),
                      value: calculatePercentage(el.neutral, sum),
                    };
                  }),
                  {
                    date: currentDate.getTime(),
                    value: calculatePercentage(state.neutral, sentimentSum),
                  },
                ],
              },
              {
                type: "positive",
                color: POSITIVE,
                data: [
                  ...(baseline
                    ? [
                        {
                          date: baseline.analyzedAt.getTime(),
                          value: calculatePercentage(
                            baseline.positive,
                            sentimentBaselineSum
                          ),
                        },
                      ]
                    : []),
                  ...state.intermediateDocs.map((el) => {
                    const sum = getSum([
                      el.mixed,
                      el.positive,
                      el.negative,
                      el.neutral,
                    ]);
                    return {
                      date: new Date(el.analyzedAt).getTime(),
                      value: calculatePercentage(el.positive, sum),
                    };
                  }),
                  {
                    date: currentDate.getTime(),
                    value: calculatePercentage(state.positive, sentimentSum),
                  },
                ],
              },
            ],
          }}
          title="Sentiment Evolution Over Time"
        />

        <PieChart
          results={{
            colors: [POSITIVE, NEGATIVE, NEUTRAL, MIXED],
            elementDiv: "chartdiv",
            elements: [
              {
                value: state.positive,
                category: "Positive",
              },
              {
                value: state.negative,
                category: "Negative",
              },
              {
                value: state.neutral,
                category: "Neutral",
              },
              {
                value: state.mixed,
                category: "Mixed",
              },
            ],
          }}
          title="“Pie” chart to show sentiment distribution of new document"
          className="mt-8"
        />
        {!!baseline && (
          <DistributionBlock<
            Pick<SentimentDto, "mixed" | "negative" | "neutral" | "positive">
          >
            results={{
              colors: [
                {
                  name: "Positive",
                  value: POSITIVE,
                },
                {
                  name: "Negative",
                  value: NEGATIVE,
                },
                {
                  name: "Neutral",
                  value: NEUTRAL,
                },
                {
                  name: "Mixed",
                  value: MIXED,
                },
              ],
              elementDiv: "chart_distribution",
              elements: [
                {
                  value: state.positive,
                  type: "positive",
                },
                {
                  value: state.negative,
                  type: "negative",
                },
                {
                  value: state.neutral,
                  type: "neutral",
                },
                {
                  value: state.mixed,
                  type: "mixed",
                },
              ],
              elementsBaseline: [
                {
                  value: baseline.positive,
                  type: "positive",
                },
                {
                  value: baseline.negative,
                  type: "negative",
                },
                {
                  value: baseline.neutral,
                  type: "neutral",
                },
                {
                  value: baseline.mixed,
                  type: "mixed",
                },
              ],
            }}
            title="Sentiment Distribution & Comparison Against Baseline"
            className="mt-12"
            elementWrapperClassName="top-[40px]"
            gridColumnNumber={4}
          />
        )}
        {!!baseline && (
          <StackedColumnChart<
            Pick<SentimentDto, "mixed" | "negative" | "neutral" | "positive">
          >
            results={{
              elementDiv: "stacked_chart",
              elements: [
                {
                  baselineValue: baseline.positive,
                  color: POSITIVE,
                  position: "left",
                  type: "positive",
                  value: state.positive,
                },
                {
                  baselineValue: baseline.negative,
                  color: NEGATIVE,
                  position: "right",
                  type: "negative",
                  value: state.negative,
                },

                {
                  baselineValue: baseline.neutral,
                  color: NEUTRAL,
                  position: "left",
                  type: "neutral",
                  value: state.neutral,
                },
                {
                  baselineValue: baseline.mixed,
                  color: MIXED,
                  position: "right",
                  type: "mixed",
                  value: state.mixed,
                },
              ],
            }}
            title="Stacked Bar Chart: Breakdown Comparison of Sentiments"
            className="mt-12"
          />
        )}
        {!!baseline && (
          <ClusteredColumnChart<
            Pick<SentimentDto, "mixed" | "negative" | "neutral" | "positive">
          >
            results={{
              elementDiv: "chart_clustered",
              elements: [
                {
                  value: state.positive,
                  type: "positive",
                },
                {
                  value: state.negative,
                  type: "negative",
                },
                {
                  value: state.neutral,
                  type: "neutral",
                },
                {
                  value: state.mixed,
                  type: "mixed",
                },
              ],
              elementsBaseline: [
                {
                  value: baseline.positive,
                  type: "positive",
                },
                {
                  value: baseline.negative,
                  type: "negative",
                },
                {
                  value: baseline.neutral,
                  type: "neutral",
                },
                {
                  value: baseline.mixed,
                  type: "mixed",
                },
              ],
            }}
            title="Bar Charts & Percent Difference: Individual Sentiment Scores"
            className="mt-12"
            elementClassName="h-[500px]"
          />
        )}
      </div>
    </div>
  );
};

export default Results;
