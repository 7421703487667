import { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import { titleCase } from "@/utils/utils";

export type StackedColumnElementType<T> = {
  value: number;
  type: keyof T;
  title?: string;
  color: string;
  baselineValue: number;
  position: "left" | "right";
};

type StackedColumnProps<T> = {
  results: {
    elements: StackedColumnElementType<T>[];
    elementDiv: string;
  };
  title: string;
  className?: string;
};

const StackedColumnChart = <T extends Record<string, number>>({
  results,
  title,
  className,
}: StackedColumnProps<T>) => {
  useLayoutEffect(() => {
    let root = am5.Root.new(results.elementDiv);

    root?._logo?.dispose();
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "none",
        wheelY: "none",
        paddingLeft: 0,
        layout: root.verticalLayout,
        paddingTop: 30,
      })
    );

    // Add scrollbar
    // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/

    const comparisonData = {} as Record<string, number>;
    results.elements.forEach((el) => {
      return (comparisonData[el.type as string] = el.value);
    });

    const comparisonBaselineData = {} as Record<string, number>;
    results.elements.forEach((el) => {
      return (comparisonBaselineData[el.type as string] = el.baselineValue);
    });
    var data = [
      {
        document: "Comparison",
        ...comparisonData,
      },
      {
        document: "Baseline",
        ...comparisonBaselineData,
      },
    ];

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    var xRenderer = am5xy.AxisRendererX.new(root, {
      minorGridEnabled: true,
    });

    var xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "document",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    xRenderer.grid.template.setAll({
      location: 1,
    });

    xAxis.data.setAll(data);

    const yRenderer = am5xy.AxisRendererY.new(root, {
      strokeOpacity: 0.1,
    });

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        max: 100,
        numberFormat: "#'%'",
        strictMinMax: true,
        calculateTotals: true,
        renderer: yRenderer,
      })
    );

    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    var legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
      })
    );

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    function makeSeries(
      name: any,
      fieldName: any,
      position: "left" | "right",
      color: any
    ) {
      var series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          stacked: true,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          valueYShow: "valueYTotalPercent",
          categoryXField: "document",
          maskBullets: false,
          fill: am5.color(color),
        })
      );

      series.columns.template.setAll({
        tooltipText: "{name}: {valueY}",
        tooltipY: am5.percent(10),
      });

      series.data.setAll(data);

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      series.appear();
      const pos = position === "left" ? 0.25 : 0.65;
      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationX: pos,
          locationY: 1,
          sprite: am5.Circle.new(root, {
            radius: 20,
            fill: am5.Color.lighten(series.get("fill" as any), 0.7),
          }),
        });
      });

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationX: pos,
          locationY: 1,
          sprite: am5.Label.new(root, {
            text: "{valueYTotalPercent.formatNumber('#.#')}%",
            fill: am5.color("#000"),
            centerY: am5.p50,
            centerX: am5.p50,
            populateText: true,
            fontSize: 12,
          }),
        });
      });

      legend.data.push(series);
      legend.valueLabels.template.setAll({
        fontSize: 14,
      });
      legend.labels.template.setAll({
        fontSize: 14,
      });
    }

    results.elements.forEach((el) => {
      makeSeries(
        titleCase(el.title || (el.type as string)),
        el.type,
        el.position,
        el.color
      );
    });

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [results.elementDiv, results.elements]);

  return (
    <div className={`${className}`}>
      <div className="text-xl font-normal text-center mb-8">{title}</div>
      <div
        id={results.elementDiv}
        style={{ width: "100%", height: "500px" }}
      ></div>
    </div>
  );
};

export default StackedColumnChart;
