import { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5percent from "@amcharts/amcharts5/percent";

export type PieChartElementType = {
  value: number;
  category: string;
};

type PieChartProps = {
  results: {
    elements: PieChartElementType[];
    elementDiv: string;
    colors: string[];
  };
  title: string;
  className?: string;
};

const PieChart = ({ results, title, className }: PieChartProps) => {
  useLayoutEffect(() => {
    let root = am5.Root.new(results.elementDiv);
    root?._logo?.dispose();

    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
      })
    );

    // Create series
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        alignLabels: true,
        calculateAggregates: true,
        valueField: "value",
        categoryField: "category",
      })
    );

    series?.get("colors")?.set(
      "colors",
      results.colors.map((el) => {
        return am5.color(el);
      })
    );

    series.slices.template.setAll({
      strokeWidth: 3,
      stroke: am5.color(0xffffff),
    });

    series.labelsContainer.set("paddingTop", 30);

    // Set up adapters for variable slice radius
    // https://www.amcharts.com/docs/v5/concepts/settings/adapters/
    series.slices.template.adapters.add(
      "radius",
      function (radius: any, target: any) {
        var dataItem = target.dataItem;
        var high = series.getPrivate("valueHigh") as any;

        if (dataItem) {
          var value = target.dataItem?.get("valueWorking", 0) as any;
          return (radius * value) / high;
        }
        return radius;
      }
    );

    // Set data
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
    series.data.setAll(results.elements);

    // Create legend
    // https://www.amcharts.com/docs/v5/charts/percent-charts/legend-percent-series/
    var legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
        marginTop: 15,
        marginBottom: 15,
      })
    );

    legend.valueLabels.template.setAll({
      fontSize: 14,

      marginRight: 15,
    });
    legend.labels.template.setAll({
      fontSize: 14,
    });

    legend.data.setAll(series.dataItems);

    // Play initial series animation
    // https://www.amcharts.com/docs/v5/concepts/animations/#Animation_of_series
    series.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [results.colors, results.elementDiv, results.elements]);

  return (
    <div className={`${className}`}>
      <div className={`text-xl font-normal p-8 text-center mb-8 `}>{title}</div>
      <div
        id={`${results.elementDiv}`}
        style={{ width: "100%", height: "500px" }}
      ></div>
    </div>
  );
};

export default PieChart;
