import { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import { BASELINE, NEW_DOC } from "@/utils/colors";

type ClusteredColumnProps = {
  name: string;
  value: number;
  baseline: number;
  difference: number;
  elementDiv: string;
  elementClassName?: string;
};

const ClusteredColumnChartTemplate = ({
  name,
  value,
  baseline,
  difference,
  elementDiv,
  elementClassName,
}: ClusteredColumnProps) => {
  useLayoutEffect(() => {
    let root = am5.Root.new(`${elementDiv}_${name}`);
    root?._logo?.dispose();
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        paddingLeft: 0,
        wheelX: "none",
        wheelY: "none",
        layout: root.verticalLayout,
        pinchZoomX: false,
        pinchZoomY: false,
      })
    );

    chart.zoomOutButton.set("forceHidden", true);

    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    var legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
      })
    );

    var data = [
      {
        document: name,
        newDoc: value,
        baseline: baseline,
      },
    ];

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    var xRenderer = am5xy.AxisRendererX.new(root, {});
    xRenderer.labels.template.set("visible", false);
    xRenderer.grid.template.set("visible", false);

    var xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "document",
        renderer: xRenderer,
        height: 100,
      })
    );

    xRenderer.grid.template.setAll({
      location: 1,
    });

    xAxis.data.setAll(data);

    var yRenderer = am5xy.AxisRendererY.new(root, {});
    yRenderer.labels.template.set("visible", false);

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: yRenderer,
        min: 0,
        zoomY: false,
        panY: false,
        zoomX: false,
        panX: false,
      })
    );

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    function makeSeries(name: any, fieldName: any) {
      var series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField: "document",
          maskBullets: false,
          fill: am5.color(fieldName === "baseline" ? BASELINE : NEW_DOC),
        })
      );

      series.columns.template.setAll({
        width: am5.percent(90),
        strokeOpacity: 0,
        tooltipText: "{name}:{valueY}",
        tooltipY: 0,
      });

      series.data.setAll(data);

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      series.appear();

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationX: 0.5,
          locationY: 1,
          sprite: am5.Circle.new(root, {
            radius: 20,
            fill: am5.Color.lighten(series.get("fill" as any), 0.7),
          }),
        });
      });

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationX: 0.5,
          locationY: 1,
          sprite: am5.Label.new(root, {
            text: "{valueY}",
            fill: am5.color("#000"),
            centerY: am5.p50,
            centerX: am5.p50,
            populateText: true,
            fontSize: 12,
          }),
        });
      });

      legend.valueLabels.template.setAll({
        fontSize: 14,
      });
      legend.labels.template.setAll({
        fontSize: 14,
      });
      legend.data.push(series);
    }

    makeSeries("Comparison", "newDoc");
    makeSeries("Baseline", "baseline");

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 100);

    const text = `Difference ${difference}%`;
    chart.children.unshift(
      am5.Label.new(root, {
        text,
        fontSize: 16,
        fontWeight: "500",
        textAlign: "center",
        x: am5.percent(50),
        y: am5.percent(2),
      })
    );

    return () => {
      root.dispose();
    };
  }, [baseline, difference, elementDiv, name, value]);

  return (
    <div className={`mb-8  ${elementClassName}`}>
      <div className="text-center">{name}</div>
      <div
        id={`${elementDiv}_${name}`}
        style={{ width: "100%", height: "100%" }}
      ></div>
    </div>
  );
};

export default ClusteredColumnChartTemplate;
