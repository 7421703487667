import { Dispatch } from "react";
import axios, { AxiosError } from "axios";
import toast from "react-hot-toast";
import { ErrorResponse, ErrorType } from "../types";
import { DocumentElemType } from "./types";

type BaselineListDocumentsProps = {
  list: DocumentElemType[];
  selected: string;
  setSelected: Dispatch<React.SetStateAction<string>>;
  errors?: ErrorType;
  setRefetch: Dispatch<React.SetStateAction<boolean>>;
};

const BaselineListDocuments = ({
  list,
  selected,
  setSelected,
  errors,
  setRefetch,
}: BaselineListDocumentsProps) => {
  const handleSelect = (e: { target: any }) => {
    setSelected(selected === e.target.value ? "" : e.target.value);
  };
  const handleDelete = async (id: string) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/documents/${id}`
      );
      setRefetch(true);
    } catch (error) {
      const err = error as AxiosError<ErrorResponse>;
      console.log(err.response?.data.errors);

      toast.error("Something went wrong", {
        position: "top-right",
      });
    }
  };

  return (
    <>
      {!!list.length && (
        <>
          <span>Documents to compare with:</span>
          <ul className="list-none flex flex-col items-start">
            {list.map((el) => {
              return (
                <li key={el.id} className="">
                  <input
                    type="checkbox"
                    name="baseDoc"
                    checked={selected === el.id}
                    value={el.id}
                    onChange={handleSelect}
                    className="mr-2"
                  />
                  <label>
                    {el.name} ({new Date(el.analyzedAt).toDateString()})
                  </label>
                  <button onClick={() => handleDelete(el.id)} className=" ml-2">
                    <span
                      style={{
                        color: "transparent",
                        textShadow: "0 0 0 red",
                      }}
                    >
                      {String.fromCharCode(10006)}
                    </span>
                  </button>
                </li>
              );
            })}
          </ul>
        </>
      )}
    </>
  );
};

export default BaselineListDocuments;
