import { Link } from "react-router-dom";
import { SocketResultPayloadElement } from "./types";

type ResultsButtonProps = {
  results?: SocketResultPayloadElement;
};

const ResultsButton = ({ results }: ResultsButtonProps) => {
  if (!results) {
    return <></>;
  }
  return (
    <div className="h-max">
      <Link
        to={{ pathname: `/results` }}
        state={results}
        className="inline-block mb-5 bg-gradient-to-r from-green-400 to-blue-500 hover:from-pink-500 hover:to-yellow-500 font-medium rounded-lg text-sm w-48 px-5 py-2.5 text-center text-white "
      >
        Open results
      </Link>
    </div>
  );
};

export default ResultsButton;
