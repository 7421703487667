import { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import axios, { AxiosError } from "axios";
import LoginForm from "./LoginForm";
import toast from "react-hot-toast";
import { ErrorResponse } from "../types";
import { AuthContext } from "../AuthContext";
import Cookies from "js-cookie";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const Login = () => {
  const cookieUserId = Cookies.get("userId");
  const { setAuthUser } = useContext(AuthContext);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState<ErrorResponse | null>(null);

  const [userId, setUserId] = useState(cookieUserId);

  useEffect(() => {
    if (!userId) {
      setIsOpen(true);
    }
    if (userId) {
      setIsOpen(false);
      setAuthUser(true);
    }
  }, [setAuthUser, userId]);

  const handleSubmit = async () => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/users/me`,
        {
          username,
          password,
        }
      );

      setUserId(result.data.id);
      Cookies.set("userId", result.data.id, {
        expires: Number(process.env.REACT_APP_AUTH_LIFETIME),
      });
      setAuthUser(true);
    } catch (error) {
      const err = error as AxiosError<ErrorResponse>;
      console.log(err.response?.data.errors);
      if (err.response?.data.errors) {
        setErrors(err.response?.data);
      }
      toast.error("Something went wrong", {
        position: "top-right",
      });
    }
  };

  const isDisabled = !username || !password;

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <div className="w-auto">
          <div className="">
            <div className="relative mb-1">
              <h2>Login</h2>
            </div>
            <div>
              <LoginForm
                setPassword={setPassword}
                setUsername={setUsername}
                isDisabled={isDisabled}
                handleSubmit={handleSubmit}
                errors={errors?.errors}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Login;
