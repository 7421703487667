import { useSearchParams } from "react-router-dom";
import {
  DocumentState,
  EmotiontDto,
  KeyPhraseDto,
  SentimentDto,
} from "../../documents/types";
import {
  ANGER,
  DISGUST,
  EMPTY,
  FEAR,
  JOY,
  MIXED,
  NEGATIVE,
  NEUTRAL,
  POSITIVE,
  SADNESS,
  SURPRISE,
} from "@/utils/colors";
import PieChart from "@/components/PieChart";
import { DistributionBlock } from "@/components/DistributionBlock";
import StackedColumnChart from "@/components/StackedColumnChart";
import ClusteredColumnChart from "@/components/ClusteredColumnChart";
import { useMemo } from "react";
import { calculatePercentage, getSum } from "@/utils/utils";
import EvolutionChart from "@/components/EvolutionChart";
import KeyPhrasesCloud from "@/components/KeyPhrasesCloud";
import { TopNegativeKeyPhrases } from "@/components/TopNegativeKeyPhrases";
import { TopPositiveKeyPhrases } from "@/components/TopPositiveKeyPhrases";

const Pdf = () => {
  let [searchParams] = useSearchParams();
  const title = searchParams.get("doc") ?? "";
  const baselineName = searchParams.get("baseline") ?? "";
  const data = useMemo(() => {
    return {
      positive: Number(searchParams.get("positive")),
      negative: Number(searchParams.get("negative")),
      mixed: Number(searchParams.get("mixed")),
      neutral: Number(searchParams.get("neutral")),
      anger: Number(searchParams.get("anger")),
      fear: Number(searchParams.get("fear")),
      empty: Number(searchParams.get("empty")),
      disgust: Number(searchParams.get("disgust")),
      joy: Number(searchParams.get("joy")),
      sadness: Number(searchParams.get("sadness")),
      surprise: Number(searchParams.get("surprise")),
      positiveBaseline: Number(searchParams.get("positiveBaseline")),
      negativeBaseline: Number(searchParams.get("negativeBaseline")),
      mixedBaseline: Number(searchParams.get("mixedBaseline")),
      neutralBaseline: Number(searchParams.get("neutralBaseline")),
      emptyBaseline: Number(searchParams.get("emptyBaseline")),
      angerBaseline: Number(searchParams.get("angerBaseline")),
      fearBaseline: Number(searchParams.get("fearBaseline")),
      disgustBaseline: Number(searchParams.get("disgustBaseline")),
      joyBaseline: Number(searchParams.get("joyBaseline")),
      sadnessBaseline: Number(searchParams.get("sadnessBaseline")),
      surpriseBaseline: Number(searchParams.get("surpriseBaseline")),
      analyzedAt: Number(searchParams.get("analyzedAt")),
      baselineAnalyzedAt: Number(searchParams.get("baselineAnalyzedAt")),
      keyPhrasesStr: searchParams.get("keyPhrases"),
      topPositiveKeyPhrasesStr: searchParams.get("topPositiveKeyPhrases"),
      topNegativeKeyPhrasesStr: searchParams.get("topNegativeKeyPhrases"),
      intermediateDocsStr: searchParams.get("intermediateDocs"),
    };
  }, [searchParams]);

  const isComparisonAvailable = useMemo(() => {
    return (
      ("angerBaseline" in data &&
        "baselineAnalyzedAt" in data &&
        "disgustBaseline" in data &&
        "fearBaseline" in data &&
        "joyBaseline" in data &&
        "mixedBaseline" in data &&
        "negativeBaseline" in data &&
        "neutralBaseline" in data &&
        "positiveBaseline" in data &&
        "sadnessBaseline" in data &&
        "surpriseBaseline" in data) ||
      false
    );
  }, [data]);

  const fileName = useMemo(() => {
    return isComparisonAvailable
      ? `${title} Analysis Report and Comparison with ${baselineName}`
      : `${title} Analysis Report`;
  }, [baselineName, isComparisonAvailable, title]);

  const currentDate = new Date(data.analyzedAt) || new Date();
  const baselineAnalyzedAt = data.baselineAnalyzedAt
    ? new Date(data.baselineAnalyzedAt)
    : null;
  const keyPhrases = data.keyPhrasesStr
    ? (JSON.parse(data.keyPhrasesStr) as KeyPhraseDto[])
    : [];

  const topPositiveKeyPhrases = data.topPositiveKeyPhrasesStr
    ? (JSON.parse(data.topPositiveKeyPhrasesStr) as string[])
    : [];

  const topNegativeKeyPhrases = data.topNegativeKeyPhrasesStr
    ? (JSON.parse(data.topNegativeKeyPhrasesStr) as string[])
    : [];

  const intermediateDocs = data.intermediateDocsStr
    ? (JSON.parse(data.intermediateDocsStr) as DocumentState[])
    : [];

  const emotionSum = useMemo(() => {
    return getSum([
      data.anger,
      data.fear,
      data.disgust,
      data.joy,
      data.sadness,
      data.surprise,
      data.empty,
    ]);
  }, [
    data.anger,
    data.disgust,
    data.empty,
    data.fear,
    data.joy,
    data.sadness,
    data.surprise,
  ]);

  const emotionBaselineSum = useMemo(() => {
    return getSum([
      data.angerBaseline,
      data.fearBaseline,
      data.disgustBaseline,
      data.joyBaseline,
      data.sadnessBaseline,
      data.surpriseBaseline,
      data.emptyBaseline,
    ]);
  }, [
    data.angerBaseline,
    data.disgustBaseline,
    data.emptyBaseline,
    data.fearBaseline,
    data.joyBaseline,
    data.sadnessBaseline,
    data.surpriseBaseline,
  ]);

  const sentimentSum = useMemo(() => {
    return getSum([data.positive, data.negative, data.neutral, data.mixed]);
  }, [data.mixed, data.negative, data.neutral, data.positive]);

  const sentimentBaselineSum = useMemo(() => {
    return getSum([
      data.positiveBaseline,
      data.negativeBaseline,
      data.neutralBaseline,
      data.mixedBaseline,
    ]);
  }, [
    data.mixedBaseline,
    data.negativeBaseline,
    data.neutralBaseline,
    data.positiveBaseline,
  ]);

  return (
    <div className="flex flex-col w-fit mx-auto ">
      <div className="text-4xl font-normal px-8 text-center mb-10">
        {fileName}
      </div>
      <EvolutionChart<
        Pick<
          EmotiontDto,
          | "anger"
          | "disgust"
          | "fear"
          | "joy"
          | "sadness"
          | "surprise"
          | "empty"
        >
      >
        results={{
          elementDiv: "emotion_evolution_chart",
          elements: [
            {
              type: "anger",
              color: ANGER,
              data: [
                ...(baselineAnalyzedAt
                  ? [
                      {
                        date: baselineAnalyzedAt.getTime(),
                        value: calculatePercentage(
                          data.angerBaseline,
                          emotionBaselineSum
                        ),
                      },
                    ]
                  : []),
                ...intermediateDocs.map((el) => {
                  const sum = getSum([
                    el.anger,
                    el.fear,
                    el.disgust,
                    el.joy,
                    el.sadness,
                    el.surprise,
                  ]);
                  return {
                    date: new Date(el.analyzedAt).getTime(),
                    value: calculatePercentage(el.anger, sum),
                  };
                }),
                {
                  date: currentDate.getTime(),
                  value: calculatePercentage(data.anger, emotionSum),
                },
              ],
            },
            {
              type: "empty",
              color: EMPTY,
              title: "No Emotions",
              data: [
                ...(baselineAnalyzedAt
                  ? [
                      {
                        date: baselineAnalyzedAt.getTime(),
                        value: calculatePercentage(
                          data.emptyBaseline,
                          emotionBaselineSum
                        ),
                      },
                    ]
                  : []),
                ...intermediateDocs.map((el) => {
                  const sum = getSum([
                    el.anger,
                    el.fear,
                    el.disgust,
                    el.joy,
                    el.sadness,
                    el.surprise,
                    el.empty,
                  ]);
                  return {
                    date: new Date(el.analyzedAt).getTime(),
                    value: calculatePercentage(el.empty, sum),
                  };
                }),
                {
                  date: currentDate.getTime(),
                  value: calculatePercentage(data.empty, emotionSum),
                },
              ],
            },
            {
              type: "disgust",
              color: DISGUST,
              data: [
                ...(baselineAnalyzedAt
                  ? [
                      {
                        date: baselineAnalyzedAt.getTime(),
                        value: calculatePercentage(
                          data.disgustBaseline,
                          emotionBaselineSum
                        ),
                      },
                    ]
                  : []),
                ...intermediateDocs.map((el) => {
                  const sum = getSum([
                    el.anger,
                    el.fear,
                    el.disgust,
                    el.joy,
                    el.sadness,
                    el.surprise,
                  ]);
                  return {
                    date: new Date(el.analyzedAt).getTime(),
                    value: calculatePercentage(el.disgust, sum),
                  };
                }),
                {
                  date: currentDate.getTime(),
                  value: calculatePercentage(data.disgust, emotionSum),
                },
              ],
            },
            {
              type: "fear",
              color: FEAR,
              data: [
                ...(baselineAnalyzedAt
                  ? [
                      {
                        date: baselineAnalyzedAt.getTime(),
                        value: calculatePercentage(
                          data.fearBaseline,
                          emotionBaselineSum
                        ),
                      },
                    ]
                  : []),
                ...intermediateDocs.map((el) => {
                  const sum = getSum([
                    el.anger,
                    el.fear,
                    el.disgust,
                    el.joy,
                    el.sadness,
                    el.surprise,
                  ]);
                  return {
                    date: new Date(el.analyzedAt).getTime(),
                    value: calculatePercentage(el.fear, sum),
                  };
                }),
                {
                  date: currentDate.getTime(),
                  value: calculatePercentage(data.fear, emotionSum),
                },
              ],
            },
            {
              type: "joy",
              color: JOY,
              title: "Contentment",
              data: [
                ...(baselineAnalyzedAt
                  ? [
                      {
                        date: baselineAnalyzedAt.getTime(),
                        value: calculatePercentage(
                          data.joyBaseline,
                          emotionBaselineSum
                        ),
                      },
                    ]
                  : []),
                ...intermediateDocs.map((el) => {
                  const sum = getSum([
                    el.anger,
                    el.fear,
                    el.disgust,
                    el.joy,
                    el.sadness,
                    el.surprise,
                  ]);
                  return {
                    date: new Date(el.analyzedAt).getTime(),
                    value: calculatePercentage(el.joy, sum),
                  };
                }),
                {
                  date: currentDate.getTime(),
                  value: calculatePercentage(data.joy, emotionSum),
                },
              ],
            },
            {
              type: "sadness",
              color: SADNESS,
              data: [
                ...(baselineAnalyzedAt
                  ? [
                      {
                        date: baselineAnalyzedAt.getTime(),
                        value: calculatePercentage(
                          data.sadnessBaseline,
                          emotionBaselineSum
                        ),
                      },
                    ]
                  : []),
                ...intermediateDocs.map((el) => {
                  const sum = getSum([
                    el.anger,
                    el.fear,
                    el.disgust,
                    el.joy,
                    el.sadness,
                    el.surprise,
                  ]);
                  return {
                    date: new Date(el.analyzedAt).getTime(),
                    value: calculatePercentage(el.sadness, sum),
                  };
                }),
                {
                  date: currentDate.getTime(),
                  value: calculatePercentage(data.sadness, emotionSum),
                },
              ],
            },
            {
              type: "surprise",
              color: SURPRISE,
              data: [
                ...(baselineAnalyzedAt
                  ? [
                      {
                        date: baselineAnalyzedAt.getTime(),
                        value: calculatePercentage(
                          data.surpriseBaseline,
                          emotionBaselineSum
                        ),
                      },
                    ]
                  : []),
                ...intermediateDocs.map((el) => {
                  const sum = getSum([
                    el.anger,
                    el.fear,
                    el.disgust,
                    el.joy,
                    el.sadness,
                    el.surprise,
                  ]);
                  return {
                    date: new Date(el.analyzedAt).getTime(),
                    value: calculatePercentage(el.surprise, sum),
                  };
                }),
                {
                  date: currentDate.getTime(),
                  value: calculatePercentage(data.surprise, emotionSum),
                },
              ],
            },
          ],
        }}
        title="Emotions Evolution Over Time"
        className="break-after-page"
      />
      <KeyPhrasesCloud results={keyPhrases} className="break-after-page" />
      <div className="flex flex-row flex-wrap break-after-page">
        <div className="flex-auto w-1/2">
          <TopPositiveKeyPhrases results={topPositiveKeyPhrases} />
        </div>
        <div className="flex-auto w-1/2">
          <TopNegativeKeyPhrases results={topNegativeKeyPhrases} />
        </div>
      </div>

      <PieChart
        results={{
          colors: [EMPTY, ANGER, DISGUST, FEAR, JOY, SADNESS, SURPRISE],
          elementDiv: "emotion_chartdiv",
          elements: [
            {
              value: data.empty,
              category: "No emotions",
            },
            {
              value: data.anger,
              category: "Anger",
            },
            {
              value: data.disgust,
              category: "Disgust",
            },
            {
              value: data.fear,
              category: "Fear",
            },
            {
              value: data.joy,
              category: "Contentment",
            },
            {
              value: data.sadness,
              category: "Sadness",
            },
            {
              value: data.surprise,
              category: "Surprise",
            },
          ],
        }}
        title="“Pie” chart to show emotion distribution of new document"
        className="mt-8 break-after-page"
      />
      {isComparisonAvailable && (
        <DistributionBlock
          gridColumnNumber={6}
          results={{
            colors: [
              {
                name: "Anger",
                value: ANGER,
              },
              {
                name: "Disgust",
                value: DISGUST,
              },
              {
                name: "Fear",
                value: FEAR,
              },
              {
                name: "Contentment",
                value: JOY,
              },
              {
                name: "Sadness",
                value: SADNESS,
              },
              {
                name: "Surprise",
                value: SURPRISE,
              },
              {
                name: "No Emotions",
                value: EMPTY,
              },
            ],
            elementDiv: "emotion_chart_distribution",
            elements: [
              {
                value: data.anger,
                type: "Anger",
              },
              {
                value: data.disgust,
                type: "Disgust",
              },
              {
                value: data.fear,
                type: "Fear",
              },
              {
                value: data.joy,
                type: "Joy",
              },
              {
                value: data.sadness,
                type: "Sadness",
              },
              {
                value: data.surprise,
                type: "Surprise",
              },
              {
                value: data.empty,
                type: "Empty",
              },
            ],
            elementsBaseline: [
              {
                value: data.angerBaseline,
                type: "Anger",
              },
              {
                value: data.disgustBaseline,
                type: "Disgust",
              },
              {
                value: data.fearBaseline,
                type: "Fear",
              },
              {
                value: data.joyBaseline,
                type: "Joy",
              },
              {
                value: data.sadnessBaseline,
                type: "Sadness",
              },
              {
                value: data.surpriseBaseline,
                type: "Surprise",
              },
              {
                value: data.emptyBaseline,
                type: "Empty",
              },
            ],
          }}
          title="Emotions Distribution & Comparison Against Baseline"
          className="mt-12 break-after-page"
          elementWrapperClassName="top-[8px]"
        />
      )}
      {isComparisonAvailable && (
        <StackedColumnChart<
          Pick<
            EmotiontDto,
            | "anger"
            | "disgust"
            | "fear"
            | "joy"
            | "sadness"
            | "surprise"
            | "empty"
          >
        >
          results={{
            elementDiv: "emotion_stacked_chart",
            elements: [
              {
                baselineValue: data.angerBaseline,
                color: ANGER,
                position: "left",
                type: "anger",
                value: data.anger,
              },
              {
                baselineValue: data.disgustBaseline,
                color: DISGUST,
                position: "right",
                type: "disgust",
                value: data.disgust,
              },
              {
                baselineValue: data.fearBaseline,
                color: FEAR,
                position: "left",
                type: "fear",
                value: data.fear,
              },
              {
                baselineValue: data.joyBaseline,
                color: JOY,
                position: "right",
                type: "joy",
                value: data.joy,
                title: "Contentment",
              },
              {
                baselineValue: data.sadnessBaseline,
                color: SADNESS,
                position: "left",
                type: "sadness",
                value: data.sadness,
              },
              {
                baselineValue: data.surpriseBaseline,
                color: SURPRISE,
                position: "right",
                type: "surprise",
                value: data.surprise,
              },
              {
                baselineValue: data.emptyBaseline,
                color: EMPTY,
                position: "left",
                type: "empty",
                value: data.empty,
                title: "No Emotions",
              },
            ],
          }}
          title="Stacked Bar Chart: Breakdown Comparison of Emotions"
          className="mt-12 break-after-page"
        />
      )}
      {isComparisonAvailable && (
        <ClusteredColumnChart<
          Pick<
            EmotiontDto,
            | "anger"
            | "disgust"
            | "fear"
            | "joy"
            | "sadness"
            | "surprise"
            | "empty"
          >
        >
          results={{
            elementDiv: "emotion_clustered_chart",
            elements: [
              {
                value: data.anger,
                type: "anger",
              },
              {
                value: data.disgust,
                type: "disgust",
              },
              {
                value: data.fear,
                type: "fear",
              },
              {
                value: data.joy,
                type: "joy",
                title: "Contentment",
              },
              {
                value: data.sadness,
                type: "sadness",
              },
              {
                value: data.surprise,
                type: "surprise",
              },
              {
                value: data.empty,
                type: "empty",
                title: "No Emotions",
              },
            ],
            elementsBaseline: [
              {
                value: data.angerBaseline,
                type: "anger",
              },
              {
                value: data.disgustBaseline,
                type: "disgust",
              },
              {
                value: data.fearBaseline,
                type: "fear",
              },
              {
                value: data.joyBaseline,
                type: "joy",
                title: "Contentment",
              },
              {
                value: data.sadnessBaseline,
                type: "sadness",
              },
              {
                value: data.surpriseBaseline,
                type: "surprise",
              },
              {
                value: data.emptyBaseline,
                type: "empty",
                title: "No Emotions",
              },
            ],
          }}
          title="Bar Charts & Percent Difference: Individual Emotion Scores"
          className="mt-12 break-after-page"
          elementClassName="h-[300px]"
        />
      )}
      <EvolutionChart<
        Pick<SentimentDto, "mixed" | "negative" | "neutral" | "positive">
      >
        results={{
          elementDiv: "evolution_chart",
          elements: [
            {
              type: "mixed",
              color: MIXED,
              data: [
                ...(baselineAnalyzedAt
                  ? [
                      {
                        date: baselineAnalyzedAt.getTime(),
                        value: calculatePercentage(
                          data.mixedBaseline,
                          sentimentBaselineSum
                        ),
                      },
                    ]
                  : []),
                ...intermediateDocs.map((el) => {
                  const sum = getSum([
                    el.mixed,
                    el.positive,
                    el.negative,
                    el.neutral,
                  ]);
                  return {
                    date: new Date(el.analyzedAt).getTime(),
                    value: calculatePercentage(el.mixed, sum),
                  };
                }),
                {
                  date: currentDate.getTime(),
                  value: calculatePercentage(data.mixed, sentimentSum),
                },
              ],
            },
            {
              type: "negative",
              color: NEGATIVE,
              data: [
                ...(baselineAnalyzedAt
                  ? [
                      {
                        date: baselineAnalyzedAt.getTime(),
                        value: calculatePercentage(
                          data.negativeBaseline,
                          sentimentBaselineSum
                        ),
                      },
                    ]
                  : []),
                ...intermediateDocs.map((el) => {
                  const sum = getSum([
                    el.mixed,
                    el.positive,
                    el.negative,
                    el.neutral,
                  ]);
                  return {
                    date: new Date(el.analyzedAt).getTime(),
                    value: calculatePercentage(el.negative, sum),
                  };
                }),
                {
                  date: currentDate.getTime(),
                  value: calculatePercentage(data.negative, sentimentSum),
                },
              ],
            },
            {
              type: "neutral",
              color: NEUTRAL,
              data: [
                ...(baselineAnalyzedAt
                  ? [
                      {
                        date: baselineAnalyzedAt.getTime(),
                        value: calculatePercentage(
                          data.neutralBaseline,
                          sentimentBaselineSum
                        ),
                      },
                    ]
                  : []),
                ...intermediateDocs.map((el) => {
                  const sum = getSum([
                    el.mixed,
                    el.positive,
                    el.negative,
                    el.neutral,
                  ]);
                  return {
                    date: new Date(el.analyzedAt).getTime(),
                    value: calculatePercentage(el.neutral, sum),
                  };
                }),
                {
                  date: currentDate.getTime(),
                  value: calculatePercentage(data.neutral, sentimentSum),
                },
              ],
            },
            {
              type: "positive",
              color: POSITIVE,
              data: [
                ...(baselineAnalyzedAt
                  ? [
                      {
                        date: baselineAnalyzedAt.getTime(),
                        value: calculatePercentage(
                          data.positiveBaseline,
                          sentimentBaselineSum
                        ),
                      },
                    ]
                  : []),
                ...intermediateDocs.map((el) => {
                  const sum = getSum([
                    el.mixed,
                    el.positive,
                    el.negative,
                    el.neutral,
                  ]);
                  return {
                    date: new Date(el.analyzedAt).getTime(),
                    value: calculatePercentage(el.positive, sum),
                  };
                }),
                {
                  date: currentDate.getTime(),
                  value: calculatePercentage(data.positive, sentimentSum),
                },
              ],
            },
          ],
        }}
        title="Sentiment Evolution Over Time"
        className="break-after-page"
      />
      <PieChart
        results={{
          colors: [POSITIVE, NEGATIVE, NEUTRAL, MIXED],
          elementDiv: "chartdiv",
          elements: [
            {
              value: data.positive,
              category: "Positive",
            },
            {
              value: data.negative,
              category: "Negative",
            },
            {
              value: data.neutral,
              category: "Neutral",
            },
            {
              value: data.mixed,
              category: "Mixed",
            },
          ],
        }}
        title="“Pie” chart to show sentiment distribution of new document"
        className="break-after-page"
      />
      {isComparisonAvailable && (
        <DistributionBlock<
          Pick<SentimentDto, "mixed" | "negative" | "neutral" | "positive">
        >
          results={{
            colors: [
              {
                name: "Positive",
                value: POSITIVE,
              },
              {
                name: "Negative",
                value: NEGATIVE,
              },
              {
                name: "Neutral",
                value: NEUTRAL,
              },
              {
                name: "Mixed",
                value: MIXED,
              },
            ],
            elementDiv: "pdf_chart_distribution",
            elements: [
              {
                value: data.positive,
                type: "positive",
              },
              {
                value: data.negative,
                type: "negative",
              },
              {
                value: data.neutral,
                type: "neutral",
              },
              {
                value: data.mixed,
                type: "mixed",
              },
            ],
            elementsBaseline: [
              {
                value: data.positiveBaseline,
                type: "positive",
              },
              {
                value: data.negativeBaseline,
                type: "negative",
              },
              {
                value: data.neutralBaseline,
                type: "neutral",
              },
              {
                value: data.mixedBaseline,
                type: "mixed",
              },
            ],
          }}
          title="Sentiment Distribution & Comparison Against Baseline"
          className="break-after-page"
        />
      )}
      {isComparisonAvailable && (
        <StackedColumnChart
          results={{
            elementDiv: "stacked_chart_pdf",
            elements: [
              {
                baselineValue: data.positive,
                color: POSITIVE,
                position: "left",
                type: "positive",
                value: data.positive,
              },
              {
                baselineValue: data.negative,
                color: NEGATIVE,
                position: "right",
                type: "negative",
                value: data.negative,
              },

              {
                baselineValue: data.neutral,
                color: NEUTRAL,
                position: "left",
                type: "neutral",
                value: data.neutral,
              },
              {
                baselineValue: data.mixed,
                color: MIXED,
                position: "right",
                type: "mixed",
                value: data.mixed,
              },
            ],
          }}
          title="Stacked Bar Chart: Breakdown Comparison of Sentiments"
          className="break-after-page"
        />
      )}
      {isComparisonAvailable && (
        <ClusteredColumnChart
          results={{
            elementDiv: "chart_clustered_pdf",
            elements: [
              {
                value: data.positive,
                type: "positive",
              },
              {
                value: data.negative,
                type: "negative",
              },
              {
                value: data.neutral,
                type: "neutral",
              },
              {
                value: data.mixed,
                type: "mixed",
              },
            ],
            elementsBaseline: [
              {
                value: data.positiveBaseline,
                type: "positive",
              },
              {
                value: data.negativeBaseline,
                type: "negative",
              },
              {
                value: data.neutralBaseline,
                type: "neutral",
              },
              {
                value: data.mixedBaseline,
                type: "mixed",
              },
            ],
          }}
          title="Bar Charts & Percent Difference: Individual Sentiment Scores"
          className="break-after-page"
          elementClassName="h-[500px]"
        />
      )}
    </div>
  );
};

export default Pdf;
