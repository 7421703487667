import * as am5 from "@amcharts/amcharts5";
import { useLayoutEffect } from "react";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import { titleCase } from "@/utils/utils";

export type EvolutionElementType<T> = {
  data: {
    value: number;
    date: number;
  }[];
  type: keyof T;
  title?: string;
  color: string;
};

type EvolutionProps<T> = {
  results: {
    elements: EvolutionElementType<T>[];
    elementDiv: string;
  };
  title: string;
  className?: string;
};

const EvolutionChart = <T extends Record<string, number>>({
  results,
  title,
  className,
}: EvolutionProps<T>) => {
  useLayoutEffect(() => {
    let root = am5.Root.new(results.elementDiv);
    root?._logo?.dispose();
    const myTheme = am5.Theme.new(root);

    myTheme.rule("AxisLabel", ["minor"]).setAll({
      dy: 1,
    });

    myTheme.rule("Grid", ["x"]).setAll({
      strokeOpacity: 0.05,
    });

    myTheme.rule("Grid", ["x", "minor"]).setAll({
      strokeOpacity: 0.05,
    });

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root), myTheme]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "none",
        wheelY: "none",
        maxTooltipDistance: 0,
        pinchZoomX: false,
        pinchZoomY: false,
      })
    );

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0.2,
        extraMax: 0.1,
        extraMin: 0.1,
        baseInterval: {
          timeUnit: "day",
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {
          minorGridEnabled: true,
        }),
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
        numberFormat: "#'%'",
        max: 100,
      })
    );

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    for (let i = 0; i < results.elements.length; i++) {
      const series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: results.elements[i].title
            ? `${titleCase(results.elements[i].title as string)}`
            : `${titleCase(results.elements[i].type.toString())}`,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "value",
          valueXField: "date",
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: "horizontal",
            labelText: `{valueY}%`,
          }),
          fill: am5.color(results.elements[i].color),
        })
      );

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationX: 0.5,
          locationY: 1,
          sprite: am5.Circle.new(root, {
            radius: 7,
            fill: series.get("fill" as any),
          }),
        });
      });

      series.data.setAll(results.elements[i].data);

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      series.appear();
    }

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    const cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "none",
      })
    );
    cursor.lineY.set("visible", false);

    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    const legend = chart.rightAxesContainer.children.push(
      am5.Legend.new(root, {
        width: 200,
        paddingLeft: 15,
        height: am5.percent(100),
      })
    );

    // When legend item container is hovered, dim all the series except the hovered one
    legend.itemContainers.template.events.on("pointerover", function (e) {
      let itemContainer = e.target;

      // As series list is data of a legend, dataContext is series
      const series = itemContainer?.dataItem?.dataContext;

      chart.series.each(function (chartSeries) {
        if (chartSeries !== series) {
          (chartSeries as any).strokes.template.setAll({
            strokeOpacity: 0.15,
            stroke: am5.color(0x000000),
          });
        } else {
          (chartSeries as any).strokes.template.setAll({
            strokeWidth: 3,
          });
        }
      });
    });

    // When legend item container is unhovered, make all series as they are
    legend.itemContainers.template.events.on("pointerout", function (e) {
      chart.series.each(function (chartSeries) {
        (chartSeries as any).strokes.template.setAll({
          strokeOpacity: 1,
          strokeWidth: 1,
          stroke: chartSeries.get("fill"),
        });
      });
    });

    chart.series.each(function (chartSeries) {
      (chartSeries as any).strokes.template.setAll({
        strokeOpacity: 1,
        strokeWidth: 1,
        stroke: chartSeries.get("fill"),
      });
    });

    legend.itemContainers.template.set("width", am5.p100);
    legend.valueLabels.template.setAll({
      width: am5.p100,
      textAlign: "left",
    });

    // It's is important to set legend data after all the events are set on template, otherwise events won't be copied
    legend.data.setAll(chart.series.values);

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [results.elementDiv, results.elements]);

  return (
    <div className={`${className}`}>
      <div className={`text-xl font-normal p-8 text-center mb-8 `}>{title}</div>
      <div
        id={`${results.elementDiv}`}
        style={{ width: "100%", height: "500px" }}
      ></div>
    </div>
  );
};

export default EvolutionChart;
