export const BASELINE = "#05716c";
export const NEW_DOC = "#031163";

export const MIXED = "#1978a5";
export const NEUTRAL = "#ced7d8";
export const NEGATIVE = "#f47a60";
export const POSITIVE = "#1fbfb8";

export const JOY = "#A020F0";
export const ANGER = "#8B0000";
export const SADNESS = "#0000FF";
export const DISGUST = "#964B00";
export const SURPRISE = "#90EE90";
export const FEAR = "#A9A9A9";

export const EMPTY = "#FFFF00";
