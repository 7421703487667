import { Dispatch } from "react";

type DocumentElemType = {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  analyzedAt: string;
};

type OptionalChartDocumentsProps = {
  list: DocumentElemType[];
  selected: string[];
  setSelected: Dispatch<React.SetStateAction<string[]>>;
};

const OptionalChartDocuments = ({
  list,
  selected,
  setSelected,
}: OptionalChartDocumentsProps) => {
  const handleSelect = (e: { target: any }) => {
    const currentIndex = selected.indexOf(e.target.value);
    const newChecked = [...selected];

    if (currentIndex === -1) {
      newChecked.push(e.target.value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelected(newChecked);
  };

  return (
    <>
      {!!list.length && (
        <>
          <span>Intermediate documents:</span>
          <ul className="list-none flex flex-col items-start">
            {list.map((el) => {
              return (
                <li key={el.id} className="">
                  <input
                    type="checkbox"
                    name="intermediateDoc"
                    checked={selected.some((e) => e === el.id)}
                    value={el.id}
                    onChange={handleSelect}
                    className="mr-2"
                  />
                  <label>
                    {el.name} ({new Date(el.analyzedAt).toDateString()})
                  </label>
                </li>
              );
            })}
          </ul>
        </>
      )}
    </>
  );
};

export default OptionalChartDocuments;
