import {
  calculateDifference,
  calculatePercentages,
  titleCase,
} from "@/utils/utils";
import ClusteredColumnChartTemplate from "./ClusteredColumnChartTemplate";

export type ClusteredColumnElementType<T> = {
  value: number;
  type: keyof T;
  title?: string;
};

type ClusteredColumnProps<T> = {
  results: {
    elements: ClusteredColumnElementType<T>[];
    elementsBaseline: ClusteredColumnElementType<T>[];
    elementDiv: string;
  };
  title: string;
  className?: string;
  elementClassName?: string;
};

const ClusteredColumnChart = <T extends Record<string, number>>({
  results,
  title,
  className,
  elementClassName,
}: ClusteredColumnProps<T>) => {
  const percentData = calculatePercentages<T>(results.elements);
  const percentDataBaseline = calculatePercentages<T>(results.elementsBaseline);

  return (
    <div className={`${className}`}>
      <div className="text-xl font-normal  text-center mb-4">{title}</div>
      <div className="grid grid-cols-2 gap-4 break-after-page">
        {percentData.map((el) => {
          const baselineValue = percentDataBaseline.find(
            (elem) => elem.type === el.type
          )!.value;

          return (
            <ClusteredColumnChartTemplate
              baseline={baselineValue}
              name={titleCase(el.title)}
              value={el.value}
              key={el.type as string}
              difference={calculateDifference(baselineValue, el.value)}
              elementDiv={results.elementDiv}
              elementClassName={elementClassName}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ClusteredColumnChart;
