import { calculateDifference, calculatePercentages } from "@/utils/utils";

type DistributionTableProps<T extends Record<string, number>> = {
  percentData: ReturnType<typeof calculatePercentages<T>>;
  percentDataBaseline: ReturnType<typeof calculatePercentages<T>>;
  colors: { name: string; value: string }[];
  className?: string;
  elementWrapperClassName?: string;
  gridColumnNumber?: number;
};

const DistributionTable = <T extends Record<string, number>>({
  percentData,
  percentDataBaseline,
  colors,
  className,
  elementWrapperClassName,
  gridColumnNumber,
}: DistributionTableProps<T>) => {
  const order = colors.map((el) => el.name);

  const percentList = percentData;
  percentList.sort(function (a, b) {
    return order.indexOf(a.type as string) - order.indexOf(b.type as string);
  });

  const percenBaselinetList = percentDataBaseline;
  percenBaselinetList.sort(function (a, b) {
    return order.indexOf(a.type as string) - order.indexOf(b.type as string);
  });
  return (
    <div className={` h-[420px] ${className}`}>
      <div className={`h-full grid grid-row-${gridColumnNumber} grid-flow-col`}>
        <div
          className={`h-full grid grid-row-${gridColumnNumber} grid-flow-row gap-2 border-r-2  mt-[20px] w-[140px] pt-[20px]`}
        >
          {colors.map((el, index) => {
            return (
              <div
                className={`relative top-[20px] ${elementWrapperClassName}`}
                key={`${el.value}_${index}`}
              >
                <div
                  className={`w-5 h-5 bg-[${el.value}] inline-block align-text-top rounded-md mr-1`}
                ></div>
                {el.name}
              </div>
            );
          })}
        </div>
        <div>
          <div>
            <div className="h-[20px] font-bold px-6">Responses</div>
          </div>
          <div
            className={`h-full grid grid-rows-${gridColumnNumber} grid-flow-row gap-2 border-r-2 pt-[20px]`}
          >
            {percentData.map((el, index) => {
              return (
                <div
                  className={`relative top-[20px]  text-center ${elementWrapperClassName}`}
                  key={`${el.value}_${index}`}
                >
                  {el.value}%
                </div>
              );
            })}
          </div>
        </div>
        <div>
          <div>
            <div className="h-[20px] font-bold px-6">Difference</div>
          </div>
          <div
            className={`h-full grid grid-rows-${gridColumnNumber} grid-flow-row gap-2 border-r-2 pt-[20px]`}
          >
            {percentData.map((el, index) => {
              return (
                <div
                  className={`relative top-[20px] text-center ${elementWrapperClassName}`}
                  key={`${el.value}_${index}`}
                >
                  {calculateDifference(
                    percenBaselinetList[index].value,
                    el.value
                  )}
                  %
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DistributionTable;
