import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import BaselineListDocuments from "./BaselineListDocuments";
import { DocumentElemType, UploadDocumentType } from "./types";
import ReactDatePicker from "react-datepicker";
import axios, { AxiosError } from "axios";

import "react-datepicker/dist/react-datepicker.css";
import { ErrorResponse } from "types";
import toast from "react-hot-toast";
import OptionalChartDocuments from "./OptionalChartDocuments";

type UploadFormProps = {
  handleSubmit: (val: UploadDocumentType) => void;
  onResetForm: Dispatch<SetStateAction<boolean>>;
  isSent: boolean;
  shouldRefetch: boolean;
};

const UploadForm = ({
  handleSubmit,
  onResetForm,
  isSent,
  shouldRefetch,
}: UploadFormProps) => {
  const [isBaseline, setIsBaseline] = useState(false);
  const [analyzedAt, setAnalyzedAt] = useState<Date>(new Date());
  const [file, setFile] = useState<File>();
  const [selected, setSelected] = useState("");
  const [intermediateSelected, setIntermediateSelected] = useState<string[]>(
    []
  );
  const [list, setList] = useState<DocumentElemType[]>([]);
  const [refetch, setRefetch] = useState<boolean>(true);

  const inputFile = useRef<HTMLInputElement | null>(null);

  const baselineHandler = () => {
    setIsBaseline(!isBaseline);
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFile(e?.target?.files?.[0]!);
    onResetForm(false);
  };

  const handleReset = () => {
    if (inputFile.current) {
      inputFile.current.value = "";
    }

    setFile(undefined);
  };

  const onSubmit = () => {
    if (file) {
      handleSubmit({
        file,
        isBaseline,
        toCompareWithDocumentId: selected,
        analyzedAt,
        intermediateSelected,
      });
    }
  };

  useEffect(() => {
    if (isSent) {
      if (inputFile.current) {
        inputFile.current.value = "";
      }

      setFile(undefined);
      setIsBaseline(false);
      setAnalyzedAt(new Date());
      setSelected("");
      setIntermediateSelected([]);
    }
  }, [isSent]);

  useEffect(() => {
    setRefetch(shouldRefetch);
  }, [shouldRefetch]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/documents`
        );

        setList(result.data[0]);
        setRefetch(false);
      } catch (error) {
        const err = error as AxiosError<ErrorResponse>;
        console.log(err.response?.data.errors);

        toast.error("Something went wrong", {
          position: "top-right",
        });
      }
    };
    if (refetch) {
      fetch();
    }
  }, [refetch]);

  const isDisabled = !file;

  return (
    <>
      <div className="flex flex-wrap flex-col rounded-2xl p-2 w-auto sm:w-3/5 mx-auto ">
        <div className="flex flex-col  flex-wrap border-cyan-100 border-4 self-center rounded-md p-4 w-full">
          <div className="flex flex-row flex-wrap items-baseline flex-auto mb-6">
            <div className="flex-auto w-48 flex flex-col shrink-0 ">
              <div className="flex flex-row justify-start">
                <input
                  className="text-sm text-grey-500 file:mr-5 file:py-2 file:px-6 file:rounded-full file:border-0 file:text-sm file:font-medium file:bg-blue-50 file:text-blue-700 hover:file:cursor-pointer hover:file:bg-amber-50 first-letter:hover:file:text-amber-700"
                  aria-describedby="file_input_help"
                  id="document"
                  type="file"
                  onChange={onChange}
                  ref={inputFile}
                />
                {!isDisabled && (
                  <button
                    type="button"
                    onClick={handleReset}
                    className="shrink-0 self-start"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="#FF0000"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                )}
              </div>
              <p className="mt-1 text-sm text-gray-500 dark:text-gray-300 flex">
                .docx format only
              </p>
            </div>
          </div>
          <div className="flex items-start mb-4">
            <div className="flex items-center h-5">
              <input
                id="isBaseline"
                type="checkbox"
                value=""
                checked={isBaseline}
                className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                onChange={baselineHandler}
              />
            </div>
            <label
              htmlFor="isBaseline"
              className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              is baseline
            </label>
          </div>
          <div className="flex flex-col">
            <div className="flex items-start">
              <div className="flex items-center h-5">
                <ReactDatePicker
                  showIcon
                  selected={analyzedAt}
                  onChange={(date) => setAnalyzedAt(date || new Date())}
                  maxDate={new Date()}
                  id="analyzedAt"
                  className="w-32 text-sm font-medium text-gray-900 dark:text-gray-300 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                />
              </div>
              <label
                htmlFor="analyzedAt"
                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                analyze date
              </label>
            </div>
            <p className="mt-2 text-sm text-gray-500 dark:text-gray-300 flex">
              if not provided the current date will be used
            </p>
          </div>
        </div>
        <div className=" text-sm mt-2  w-full self-start flex flex-col items-start px-4">
          <BaselineListDocuments
            list={list}
            selected={selected}
            setSelected={setSelected}
            setRefetch={setRefetch}
          />
        </div>
        <div className=" text-sm mt-2  w-full self-start flex flex-col items-start px-4">
          <OptionalChartDocuments
            list={list}
            selected={intermediateSelected}
            setSelected={setIntermediateSelected}
          />
        </div>

        <div className="flex justify-end self-center mr-4 mt-3">
          <button
            type="submit"
            className={` text-white ${
              !isDisabled
                ? "bg-blue-700 hover:bg-blue-800"
                : "bg-gray-500 cursor-not-allowed"
            }  bg-blue-700 font-medium rounded-lg text-sm w-24 px-5 py-2.5 text-center `}
            onClick={onSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

export default UploadForm;
